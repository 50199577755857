import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ title, description, image, type, keywords, published, modified }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultAuthor,
    defaultKeywords,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    keywords: keywords || defaultKeywords,
    author:defaultAuthor,
    date_published: published,
    date_modified: modified,
  }

  const schemaType = type

  let schemaArticle = []
  
  if (schemaType === "article") 
  {
    schemaArticle  ={

      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: seo.author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: seo.author,
      },
      copyrightYear: "2021",
      creator: {
        "@type": "Person",
        name: seo.author,
      },
      publisher: {
        "@type": "Organization",
        name: seo.author,
        logo: {
          "@type": "ImageObject",
          url: `${seo.image}`,
        },
      },
      datePublished: seo.date_published,
      dateModified: seo.date_modified,
      description: seo.description,
      headline: seo.title,
      url: seo.url,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }
  }




  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>


      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {(seo.keywords !== '' ? true : null) &&<meta name="keywords" content={seo.keywords} />}

      {seo.url && <meta property="og:url" content={seo.url} />}

      {<meta property="og:type" content={seo.url} />}

      {seo.title && <meta property="og:title" content={seo.title} />}


      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      {schemaType === "article" && (
        <script type="application/ld+json">
        {JSON.stringify(schemaArticle)}
        </script>
      )}

    </Helmet>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  keywords:PropTypes.string,
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  keywords: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultAuthor: author
        defaultKeywords: keywords
      }
    }
  }
`